import { useEffect, useState } from 'react';
// import useLesson from '../useLesson/useLesson';
import { useAppState } from '../../state';
import { REACT_APP_IS_DEMO_MODE } from '../../configs';
import useLesson from '../useLesson/useLesson';

export default function useIsRoomEEntry() {
  const [isNotRoomEntry, setIsNotRoomEntry] = useState<boolean>(true);
  const { lessonInfo } = useAppState();
  const { lesson, getLessonEndDatetimeExact, getLessonStartDatetimeExact } = useLesson({
    organization_key: lessonInfo.organization_key,
    roomName: lessonInfo.roomName,
  });
  useEffect(() => {
    if (REACT_APP_IS_DEMO_MODE) {
      // デモモードの時は常に入ることができる
      setIsNotRoomEntry(false);
    } else {
      if (lesson) {
        const lessonStartTimeFormatted = getLessonStartDatetimeExact();
        const lessonEndTimeFormatted = getLessonEndDatetimeExact();
        console.log(lessonStartTimeFormatted, lessonEndTimeFormatted);

        const lessonStartDatetime = new Date(lessonStartTimeFormatted);
        const lessonEndDatetime = new Date(lessonEndTimeFormatted);
        const now = new Date();

        const startDiff = (lessonStartDatetime.getTime() - now.getTime()) / 1000;
        const endDiff = (lessonEndDatetime.getTime() - now.getTime()) / 1000;

        //get remaining minuites
        const startTimeDIff = Math.round(startDiff / 60);
        const endTimeDiff = Math.round(endDiff / 60);

        //enable button on lesson day and before 5 minuites of lesson
        console.log(lessonStartDatetime, lessonEndDatetime, startDiff, endDiff);
        console.log(startTimeDIff, endTimeDiff);
        if (startTimeDIff <= 5 && endTimeDiff >= 0) {
          setIsNotRoomEntry(false);
        }
      }
    }
    return () => {};
  }, [lesson, getLessonEndDatetimeExact, getLessonStartDatetimeExact]);
  return { isNotRoomEntry };
}
